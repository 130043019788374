import { createHttpEndpoint } from '../../utils'

export const getAlgoliaRecommendationConfiguration = createHttpEndpoint<{
  apiKey: string
  indexName: string
}>({
  method: 'GET',
  operationId: 'recommendationConfiguration',
  path: '/bm/recommendation/configuration',
})
